import React, {useEffect} from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {TextDirection, LocalizationMap, Viewer, Worker} from '@react-pdf-viewer/core';
import fr_FR from "@react-pdf-viewer/locales/lib/fr_FR.json";
import us_US from "@react-pdf-viewer/locales/lib/en_US.json";
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {zoomPlugin} from '@react-pdf-viewer/zoom';
// Import styles
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const styles = {
    "rpv-default-layout__sidebar-tabs": {
        display: "flex",
        height: "100%",
        width: "100%"
    },
    "rpv-default-layout__sidebar-tab": {
        display: "flex",
        height: "100%",

    },
    "rpv-default-layout__sidebar-content--opened": {
        display: "flex",
        height: "100%",
        width: "80%"
    }
}
const OhadarbLawPDFViewBody = ({ohadArbLaw, ohadArbLaws}: { ohadArbLaw: any, ohadArbLaws: any }) => {

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const zoomPluginInstance = zoomPlugin({enableShortcuts: true});
    const {activateTab} = defaultLayoutPluginInstance;
    const {zoomTo} = zoomPluginInstance;
    const {t, i18n} = useTranslation()
    useEffect(() => {

        zoomTo(1.2)
        activateTab(1);

        return () => {
            i18n.language
        };
    }, [i18n.language, zoomTo, activateTab]);


    return (
        <section>
            <div className="container">
                <div className="row gx-0">
                    <div className="col-xl-10 my-7" style={{

                        borderRadius: "15px"
                    }}>

                        {ohadArbLaw.docFileName ?
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.js">
                                <div
                                    style={{
                                        height: '80vh',
                                        width: '100%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        scrollbarWidth: 'thin'
                                    }}
                                >
                                    <Viewer
                                        fileUrl={`${ohadArbLaw.docFileName}`}
                                        plugins={[
                                            defaultLayoutPluginInstance,
                                            zoomPluginInstance
                                        ]}
                                        localization={i18n.language == "fr" ? fr_FR as unknown as LocalizationMap : us_US as unknown as LocalizationMap}
                                        theme={{
                                            direction: TextDirection.LeftToRight,
                                        }}
                                    />
                                </div>
                            </Worker> :
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title mx-10">{t('FileNotFound')}</h5>
                                    <p className="card-text mx-9">{t('NotFoundMessage')}</p>
                                    <i className="uil uil-file-exclamation card-img-overlay fs-65"></i>
                                </div>
                            </div>
                        }
                    </div>

                    <aside className="col-xl-2 sticky-sidebar mt-md-0 py-16 px-2 d-none d-xl-block">
                        <div className="widget">
                            <h6> {t('UsefullLink')} :</h6>
                            <nav id="sidebar-nav">
                                <ul className="list-unstyled text-reset fs-16 fw-light">
                                    {
                                        ohadArbLaws && ohadArbLaws.filter((item: any, id: number) => id <= 5)
                                            .map((ohadArbLaw1: any, index: number) => {
                                                return (
                                                    <li key={index}>
                                                        <a className={ohadArbLaw1.id == ohadArbLaw.id ? "active" : ""}
                                                           href={`/app/ohadarbLaws/${ohadArbLaw1.id}`}>
                                                            {ohadArbLaw1.title}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                    }
                                </ul>
                            </nav>
                        </div>
                    </aside>
                </div>
            </div>
        </section>
    )
}

export default OhadarbLawPDFViewBody
