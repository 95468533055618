import React from "react";
import OhadarbLawViewHeader from "./OhadarbLawViewHeader";
import OhadarbLawPDFViewBody from "./OhadarbLawPDFViewBoby";

const OhadarbLawViewContent = ({ohadArbLaw, ohadArbLaws}:{ ohadArbLaw: any, ohadArbLaws:any }) => {

    return (
        <>
            <OhadarbLawViewHeader ohadArbLaw={ohadArbLaw}/>
            <OhadarbLawPDFViewBody ohadArbLaw={ohadArbLaw} ohadArbLaws={ohadArbLaws}/>
        </>
    )
}

export default OhadarbLawViewContent
