import React from 'react'
import {useTranslation} from "gatsby-plugin-react-i18next";

const OhadarbLawViewHeader = ({ohadArbLaw}:{ ohadArbLaw: any }) => {
    const { t } = useTranslation()
    return (
        <>
            <section className="wrapper bg-soft-primary">
                <div className="container pt-4 pb-2 pt-md-5 pb-md-2 text-center">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                        <div className="col-xl-12 col-xxl-12 mx-auto">
                            <h1 className="display-1 mb-3">{ohadArbLaw.title}</h1>
                            <nav className="d-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">{t('home')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {ohadArbLaw.title}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-flex flex-row flex-wrap p-3 justify-content-around align-items-center w-100">
                            <div className="d-flex flex-row justify-content-around align-items-center w-65 p-1 shadow-lg">
                                <p className="text-black">{t('Date Of Journal')} : </p>
                                <p className="text-blue px-3 fw-bold">{new Date(ohadArbLaw.dateOfJournal).toLocaleDateString()}</p>
                            </div>
                            <div className="d-flex flex-row justify-content-around align-items-center mw-65 p-1 shadow-lg">
                                <p className="text-black">{t('adoptedAt')} : </p>
                                <p className="text-green px-3 fw-bold">{new Date(ohadArbLaw.adoptedAt).toLocaleDateString()}</p>
                            </div>
                            <div className="d-flex flex-row justify-content-around align-items-center mw-65 p-1 shadow-lg">
                                <p className="text-black">{t('journal Number')} : </p>
                                <p className="text-aqua px-3 fw-bold"> {ohadArbLaw.journalNumber} </p>
                            </div>
                            <div className="d-flex flex-row justify-content-around align-items-center w-65 p-1 shadow-lg">
                                <p className="text-black">{t('country')} : </p>
                                <p className="text-green px-3 fw-bold">{ohadArbLaw.place}</p>
                            </div>
                            {/*<div className="d-flex flex-row justify-content-around align-items-center w-65 p-1 shadow-lg">*/}
                            {/*    <p className="text-black">Numéro de Decision : </p>*/}
                            {/*    <p className="text-green px-3 fw-bold">NWQ-456-N°45.</p>*/}
                            {/*</div>*/}

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default OhadarbLawViewHeader;
