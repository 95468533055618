import React from "react";
import {capitalize, loadScript} from "../../util/helper";
import Layout from "../../components/layout/Layout";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import {graphql} from "gatsby";
import OhadarbLawViewContent from "../../components/OhadarbLawView/OhadarbLawViewContent";
import { useParams} from "@reach/router"
import {getLawById, getOhadarbLaws} from "../../queries";
import {useTranslation} from "gatsby-plugin-react-i18next";

const OhadarbLawView = ({ lawId } : any) => {

    const title = capitalize(lawId?.split('-')?.join(' '))
    const params = useParams();
    const {i18n} = useTranslation()
    const id = params?.lawId;
    const [ohadArbLaw, setOhadArbLaw] = React.useState<any>(null);
    const [ohadArbLaws, setOhadArbLaws] = React.useState<any>(null);

    useEffect(() => {
        getLawById(id, setOhadArbLaw)
        getOhadarbLaws(setOhadArbLaws, i18n.language)
        loadScript("/assets/js/plugins.js");
        setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)
    }, [id])

    return (
        <>
            <Helmet>
                <title>OHADA | {title}</title>
            </Helmet>
            <Layout title={`OHADARB | ${title}`}>
                {ohadArbLaw === null ?
                    <div className="page-loader"></div>:
                    <OhadarbLawViewContent ohadArbLaw={ohadArbLaw} ohadArbLaws={ohadArbLaws} />
                }
            </Layout>
        </>
    )
}

export default OhadarbLawView;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
